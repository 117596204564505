interface Props {
  string: string
  length: number
}

const maxCharacterLength = ({ string, length }: Props) => {
  if (string.length > length) {
    const endChars = string.substring(string.length - 4)
    return string.substring(0, length) + '...' + endChars
  } else {
    return string
  }
}

export default maxCharacterLength
